import React from "react"
import styled from "styled-components"
import RootStockSVG from "../images/rootstock_partner.svg"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 30px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    @media only screen and (max-width: 1024px) {
        margin-top: 40px;
        margin-bottom: 0;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 90px;
    }
`;

const DetailFlex = styled.div`
    display: flex;
    justify-content: center;
    background: #EBEFF7;
    margin-bottom: 100px;
    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
        margin-bottom: 60px;
    }

    #rootStock {
        width: 250px;
        margin-right: 50px;
    }
`;

const DetailText = styled.div`
    padding: 47px 0 100px;
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap;

    p {
        width: calc(100% - 300px);
    }

    @media only screen and (max-width: 1140px) {
        padding: 0 0 100px;

        p {
            width: 100%;
            margin-top: 35px;
        }
    }
`

const Details = () => (
    <>
        <DetailFlex>
            <DetailText>
                <img src={RootStockSVG} alt="Rootstock Partners Logo" id="rootStock"/>
                <p className="intro">Rootstock Cloud ERP is built on the market-leading Salesforce Cloud Platform, sharing the same cloud as Salesforce CRM. When combined with Salesforce CRM, Rootstock Cloud ERP offers a single platform to grow and manage your business with a single customer view.</p>
            </DetailText>
        </DetailFlex>
        <WrapperDiv>
        <Flex>
            <h4 className="margin-bottom-0">Sales</h4>
            <p className="margin-bottom-60">By connecting Salesforce CRM to Rootstock Cloud ERP, your sales team can provide world-class customer service and increase sales while you drive efficiencies throughout your manufacturing operations</p>

            <h4 className="margin-bottom-0">Inventory Management</h4>
            <p className="margin-bottom-60">Precisely manage inventory and costs in real-time, across multiple locations, and down to the lot and serial number – all from any device.</p>

            <h4 className="margin-bottom-0">Sales Order Processing</h4>
            <p className="margin-bottom-60">Rootstock Cloud ERP sales order management software integrates with Salesforce Sales Cloud to streamline, automate, and accelerate sales order processing.</p>

            <h4 className="margin-bottom-0">Engineering</h4>
            <p className="margin-bottom-60">Easily create and manage BOMs, ensure production accuracy, and track detailed costs with Rootstock’s ERP change management software for engineers.</p>

            <h4 className="margin-bottom-0">Supply Chain</h4>
            <p className="margin-bottom-60">Stay ahead of demand with Rootstock’s ERP supply chain system. With automated supply chain visibility and management at your fingertips, Rootstock SCM ERP helps planners stay in sync with supplier capabilities, production needs, and customer demand.</p>

            <h4 className="margin-bottom-0">Production Planning</h4>
            <p className="margin-bottom-60">Easily create and manage BOMs, ensure production accuracy, and track detailed costs with Rootstock’s ERP change management software for engineers.</p>

            <h4 className="margin-bottom-0">Financials</h4>
            <p className="margin-bottom-60">With Rootstock Financials, ERP accounting software, organizations can easily add full financial visibility, analysis, and reporting for accounting and finance teams, with integrated capabilities for purchasing, accounts payable, and general ledger.</p>

            <h4 className="margin-bottom-0">Analytics</h4>
            <p className="margin-bottom-60">Rootstock customer ERP software integrated with Salesforce Service Cloud gives service teams 360° insights into customer interactions, sales orders, product details, and shipping information, all on one platform.</p>
        </Flex>
        </WrapperDiv>
    </>
)

export default Details

